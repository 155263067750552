import {
    announcementCreateURL,
    announcementDeleteURL,
    announcementListURL,
    announcementMarkedURL,
    announcementMonthlyListURL,
    announcementUpdateURL
} from './ApiConstant';
import { AxiosAuthServices } from './axios/axiosServices';

export function AnnouncementListApi(params) {
    return AxiosAuthServices.get(announcementListURL, params);
}

export function AnnouncementMonthlyListApi() {
    return AxiosAuthServices.get(announcementMonthlyListURL);
}

export function AnnouncementMarkAsReadApi(data) {
    const formData = new FormData();
    formData.append('id', data.id);
    return AxiosAuthServices.post(announcementMarkedURL, formData);
}

export function AnnouncementCreateUpdateApi(data) {
    const formData = new FormData();
    formData.append('name', data.name);
    formData.append('start_date', data.start_date);
    formData.append('end_date', data.end_date);
    formData.append('details', data.details);
    formData.append('status', data.status);

    if (data.id !== '' && data.formType === 'edit') {
        formData.append('id', data.id);
        return AxiosAuthServices.post(announcementUpdateURL, formData);
    } else {
        return AxiosAuthServices.post(announcementCreateURL, formData);
    }
}

export function AnnouncementDeleteApi(data) {
    const formData = new FormData();
    formData.append('id', data.id);
    return AxiosAuthServices.post(announcementDeleteURL, formData);
}
