import PermissionJson from 'assets/json/permission.json';
import RestrictionJson from 'assets/json/restriction.json';

/**
 * Checks and retrieves the permissions and restrictions based on the provided access object.
 *
 * @param {Object} access - The access object containing permissions and restrictions.
 * @param {Array} access.permissions - Array of permission IDs to check.
 * @param {Array} access.restrictions - Array of restriction IDs to check.
 * @returns {Object} - An object containing arrays of permission and restriction slugs.
 */
export function CheckPermissions(access) {
    // Filter the PermissionJson to include only items where the id matches any id in access.permissions
    const permissionsData = PermissionJson.filter((a) => access.permissions.some((b) => b == a.id));
    // Map the filtered permissions data to extract the slug property of each item
    const permissionsArr = permissionsData.map((item) => item.slug);

    // Filter the RestrictionJson to include only items where the id matches any id in access.restrictions
    const restrictionData = RestrictionJson.filter((a) => access.restrictions.some((b) => b == a.id));
    // Map the filtered restrictions data to extract the slug property of each item
    const restrictionsArr = restrictionData.map((item) => item.slug);

    // Return an object containing arrays of permission and restriction slugs
    return { permissions: permissionsArr, restrictions: restrictionsArr };
}
