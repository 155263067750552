import propTypes from 'prop-types';
import { FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { Formik } from 'formik';
import { useEffect, useState } from 'react';
import Required from 'ui-component/Required';
import * as yup from 'yup';
import { apiErrorSnackBar, apiSuccessSnackBar } from 'utils/SnackBar';
import { useDispatch, useSelector } from 'react-redux';
import { LOGIN } from 'store/actions';
import { EmployeeProjectApi } from 'apis/Project';
import { TaskSelectionListApi } from 'apis/Task';
import { TimeSheetInEventApi } from 'apis/TimeSheet';

const validationSchema = yup.object().shape({
    project: yup.string().required('Project is required.'),
    task: yup.string().required('Task is required.')
});

const TaskSelection = ({ formId, onSubmit }) => {
    const dispatch = useDispatch();
    const [projectList, setProjectList] = useState([]);
    const [taskList, setTaskList] = useState([]);
    const TimeSheetData = useSelector((state) => state.account.time_sheet);
    const allStateData = useSelector((state) => state.account);

    useEffect(() => {
        EmployeeProjectApi()
            .then((res) => {
                setProjectList(res.data.data);
            })
            .catch((err) => apiErrorSnackBar(err));
    }, []);

    const ProjectSelectTaskGet = (val) => {
        TaskSelectionListApi({ project_id: val })
            .then((res) => {
                setTaskList(res.data.data);
            })
            .catch((err) => apiErrorSnackBar(err));
    };

    return (
        <Formik
            initialValues={{
                project: '',
                task: '',
                formType: 'add'
            }}
            validationSchema={validationSchema}
            onSubmit={(values, { resetForm }) => {
                let UpdatedVal = {};

                UpdatedVal.type = 'Clock';
                UpdatedVal.timesheet_id = TimeSheetData ? TimeSheetData.id : '';
                UpdatedVal.project_id = values.project;
                UpdatedVal.task_id = values.task;
                UpdatedVal.description = values.description;
                TimeSheetInEventApi(UpdatedVal)
                    .then((res) => {
                        if (res.data && res.data.status === true) {
                            if (onSubmit) onSubmit();
                            resetForm();
                            dispatch({
                                type: LOGIN,
                                payload: {
                                    ...allStateData,
                                    task: res.data?.data?.task,
                                    time_sheet: res.data?.data?.time_sheet
                                }
                            });
                            apiSuccessSnackBar(res);
                        } else {
                            apiErrorSnackBar(res);
                        }
                    })
                    .catch((err) => {
                        apiErrorSnackBar(err);
                    });
            }}
        >
            {({ values, errors, touched, handleChange, handleSubmit }) => (
                <form id={formId} onSubmit={handleSubmit}>
                    <Grid container alignItems="center" spacing={2}>
                        <Grid item xs={12} sm={12}>
                            <FormControl size="small" fullWidth error={touched.project && Boolean(errors.project)}>
                                <InputLabel id="projectLabel">
                                    <Required title="Project" />
                                </InputLabel>
                                <Select
                                    fullWidth
                                    labelId="projectLabel"
                                    id="project"
                                    name="project"
                                    label={<Required title="Project" />}
                                    value={values.project}
                                    onChange={(e) => {
                                        handleChange(e);
                                        ProjectSelectTaskGet(e.target.value);
                                    }}
                                >
                                    {projectList.length > 0 &&
                                        projectList.map((item, idx) => (
                                            <MenuItem value={item.id} key={idx}>
                                                {item.name}
                                            </MenuItem>
                                        ))}
                                </Select>
                                <FormHelperText>{touched.project && errors.project}</FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <FormControl size="small" fullWidth error={touched.task && Boolean(errors.task)}>
                                <InputLabel id="taskLabel">
                                    <Required title="Task" />
                                </InputLabel>
                                <Select
                                    fullWidth
                                    labelId="taskLabel"
                                    id="task"
                                    name="task"
                                    label={<Required title="Task" />}
                                    value={values.task}
                                    onChange={(e) => {
                                        handleChange(e);
                                    }}
                                >
                                    {taskList.length > 0 &&
                                        taskList.map((item, idx) => (
                                            <MenuItem value={item.id} key={idx}>
                                                {item.name}
                                            </MenuItem>
                                        ))}
                                </Select>
                                <FormHelperText>{touched.task && errors.task}</FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <TextField
                                fullWidth
                                multiline
                                size="small"
                                rows={4}
                                id="description"
                                name="description"
                                label="Description"
                                value={values.description}
                                onChange={handleChange}
                                error={touched.description && Boolean(errors.description)}
                                helperText={touched.description && errors.description}
                            />
                        </Grid>
                    </Grid>
                </form>
            )}
        </Formik>
    );
};

TaskSelection.propTypes = {
    onSubmit: propTypes.func,
    formId: propTypes.string
};

export default TaskSelection;
