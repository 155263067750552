import { TASK_SELECTION } from './actions';

export const initialState = {
    taskSelect: false
};

const commonReducer = (state = initialState, action) => {
    switch (action.type) {
        case TASK_SELECTION:
            const { taskSelect } = action.payload;
            return {
                taskSelect
            };
        default:
            return { ...state };
    }
};

export default commonReducer;
