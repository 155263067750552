import { NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// material-ui
import { ButtonBase } from '@mui/material';

// project imports
import config from 'config';
import { MENU_OPEN } from 'store/actions';
import CompanyLogo from 'assets/images/companyLogo.png';
import useAuth from 'hooks/useAuth';

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = () => {
    const Selector = useSelector((state) => state);
    const { user } = useAuth();
    const defaultId = Selector.customization.defaultId;
    const dispatch = useDispatch();
    const MainLogo = Selector.account.company_logo || CompanyLogo;

    return (
        <ButtonBase
            disableRipple
            component={NavLink}
            to={config.defaultPath}
            onClick={() => {
                dispatch({ type: MENU_OPEN, id: defaultId });
            }}
        >
            <img src={MainLogo} alt="logo" height="45" />
        </ButtonBase>
    );
};

export default LogoSection;
