import moment from 'moment';
import {
    AttendanceByIdAndDateURL,
    EmployeeWorkStatusUpdateURL,
    MonthlyAttendanceListExportURL,
    MonthlyAttendanceListURL,
    attendanceDataURL,
    attendanceSheetInitURL,
    attendanceSheetListURL,
    dailyDataAddURL,
    dailyDataDeleteURL,
    dailyDataUpdateURL,
    exportAttendanceSheetListURL,
    timeSheetInEventURL,
    timeSheetInitURL,
    timeSheetNewCreateURL,
    timeSheetNewUpdateURL,
    timeSheetOutEventURL
} from './ApiConstant';
import { AxiosAuthServices } from './axios/axiosServices';

export function TimeSheetInitApi(params) {
    return AxiosAuthServices.get(timeSheetInitURL, params);
}

export function AttendanceDataInitApi(params) {
    return AxiosAuthServices.get(attendanceDataURL, params);
}

export function AttendanceSheetInitApi() {
    return AxiosAuthServices.get(attendanceSheetInitURL);
}

export function AttendanceSheetListApi(params) {
    return AxiosAuthServices.get(attendanceSheetListURL, params);
}

export function ExportAttendanceSheetListApi(params) {
    return AxiosAuthServices.get(exportAttendanceSheetListURL, params);
}

export function TimeSheetCreateUpdateApi(data) {
    const formData = new FormData();
    // formData.append('user_id', data.user_id);
    formData.append('type', data.type);
    formData.append('task_time_id', data.task_time_id);

    if (data.time_sheet_id != null && data.formType === 'edit') {
        formData.append('event_id', data.event_id);
        formData.append('timesheet_id', data.time_sheet_id);
        formData.append('office_shift_id', data.office_shift_id);
        formData.append('break_id', data.break_id);
        return AxiosAuthServices.post(timeSheetNewUpdateURL, formData);
    } else {
        return AxiosAuthServices.post(timeSheetNewCreateURL, formData);
    }
}

export function EmployeeWorkStatusUpdateApi(data) {
    const formData = new FormData();
    if (data.timesheet_id != null) {
        formData.append('timesheet_id', data.timesheet_id);
        formData.append('is_work_from_home', data.is_work_from_home);
        formData.append('is_on_site', data.is_on_site);
        return AxiosAuthServices.post(EmployeeWorkStatusUpdateURL, formData);
    }
}

export function MonthlyAttendanceListApi(params) {
    return AxiosAuthServices.get(MonthlyAttendanceListURL, params);
}

export function MonthlyAttendanceListExportApi(params) {
    return AxiosAuthServices.get(MonthlyAttendanceListExportURL, params);
}

export function AttendanceByIdAndDateApi(params) {
    return AxiosAuthServices.get(AttendanceByIdAndDateURL, params);
}

export function TimeSheetInEventApi(params) {
    return AxiosAuthServices.post(timeSheetInEventURL, params);
}

export function TimeSheetOutEventApi(params) {
    return AxiosAuthServices.post(timeSheetOutEventURL, params);
}

export function DailyDataUpdateApi(data) {
    const formData = new FormData();
    if (data.id != null) {
        formData.append('start_time', moment(data.start_time).format('HH:mm:ss'));
        formData.append('end_time', moment(data.end_time).format('HH:mm:ss'));
        formData.append('check_leave', `${data.add_leave}`);
        formData.append('timesheet_id', data.timesheet_id);

        if (data.form_type === 'edit') {
            formData.append('id', data.id);
            return AxiosAuthServices.post(dailyDataUpdateURL, formData);
        } else {
            formData.append('employee_id', data.employee_id);
            formData.append('user_id', data.user_id);
            formData.append('type', data.type);
            return AxiosAuthServices.post(dailyDataAddURL, formData);
        }
    }
}

export function DailyDataDeleteApi(params) {
    return AxiosAuthServices.post(dailyDataDeleteURL, params);
}
