import { LoadingButton } from '@mui/lab';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import propTypes from 'prop-types';
import { useEffect, useState } from 'react';
import AnimateButton from 'ui-component/extended/AnimateButton';

const CenterDialog = ({
    open,
    title,
    subTitle,
    sx = {},
    contentSX = {},
    saveButton,
    saveButtonTitle,
    closeButton,
    id,
    onClose,
    children,
    isLoading
}) => {
    const [openDialog, setOpenDialog] = useState(false);

    useEffect(() => {
        setOpenDialog(open ?? false);
    }, [open]);

    const handleCloseDialog = () => {
        setOpenDialog((prevState) => !prevState);
        open = false;
        if (onClose) onClose();
    };

    return (
        <Dialog
            open={openDialog}
            keepMounted
            // onClose={handleCloseDialog}
            sx={{
                '&>div:nth-of-type(3)': {
                    '&>div': {
                        minWidth: { md: '30%', xs: '90%' }
                    }
                },
                ...sx
            }}
        >
            {title && (
                <DialogTitle>
                    <Typography variant="h4" gutterBottom component="div">
                        {title}
                        {subTitle && <Typography variant="body2">{subTitle}</Typography>}
                    </Typography>
                </DialogTitle>
            )}
            <DialogContent dividers sx={contentSX}>
                {children}
            </DialogContent>
            <DialogActions>
                {!saveButton && (
                    <AnimateButton>
                        {/* <Button variant="contained" color="primary" type="submit" form={id}>
                            Save
                        </Button> */}
                        <LoadingButton loading={isLoading} variant="contained" color="primary" type="submit" form={id} disabled={isLoading}>
                            {saveButtonTitle ? saveButtonTitle : 'Save'}
                        </LoadingButton>
                    </AnimateButton>
                )}
                {!closeButton && (
                    <Button variant="text" color="error" onClick={handleCloseDialog}>
                        Close
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    );
};

CenterDialog.propTypes = {
    open: propTypes.bool,
    title: propTypes.node,
    subTitle: propTypes.string,
    sx: propTypes.object,
    contentSX: propTypes.object,
    saveButton: propTypes.bool,
    closeButton: propTypes.bool,
    id: propTypes.string,
    onClose: propTypes.func,
    children: propTypes.node,
    saveButtonTitle: propTypes.string,
    isLoading: propTypes.bool
};

export default CenterDialog;
