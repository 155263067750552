import { LOGIN, LOGOUT } from './actions';

export const initialState = {
    isLoggedIn: false,
    isLoggedInWithOtherUser: false,
    actualUserData: {},
    user: {},
    access: {},
    task: {},
    time_sheet: null,
    clock: null,
    breakActive: null,
    taskSelect: false,
    company_logo: ''
};
const accountReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOGIN: {
            const {
                user,
                access,
                task,
                clock,
                breakActive,
                time_sheet,
                taskSelect,
                actualUserData,
                isLoggedInWithOtherUser,
                company_logo
            } = action.payload;
            return {
                ...state,
                isLoggedIn: action.payload.isLoggedIn,
                user,
                access,
                task,
                clock,
                breakActive,
                time_sheet,
                taskSelect,
                actualUserData,
                isLoggedInWithOtherUser,
                company_logo
            };
        }
        case LOGOUT: {
            return {
                ...state,
                isLoggedIn: false,
                isLoggedInWithOtherUser: false,
                actualUserData: {},
                user: {},
                access: {},
                task: {},
                time_sheet: null,
                clock: null,
                breakActive: null,
                taskSelect: false
            };
        }
        default: {
            return { ...state };
        }
    }
};

export default accountReducer;
