import useAuth from 'hooks/useAuth';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { store } from 'store';
import { LOGOUT } from 'store/actions';

const AuthGuard = ({ children }) => {
    const { isLoggedIn } = useAuth();
    const navigate = useNavigate();
    const serviceToken = localStorage.getItem('serviceToken');

    useEffect(() => {
        if (!isLoggedIn || !serviceToken) {
            localStorage.removeItem('serviceToken');
            store.dispatch({ type: LOGOUT });
            navigate('/login');
        } else if (document.location.pathname === '/login') {
            navigate('/');
        }
    }, [isLoggedIn, navigate]);
    return isLoggedIn ? children : null;
};

AuthGuard.propTypes = {
    children: PropTypes.node
};

export default AuthGuard;
