import {
    ProjectTaskNewListURL,
    TaskCommentDeleteURL,
    TaskCommentListURL,
    TaskCommentUpdateURL,
    TaskListURL,
    TaskLogURL,
    TaskSelectionURL,
    projectTaskCommentCreateURL
} from './ApiConstant';
import { AxiosAuthServices } from './axios/axiosServices';

// export function TaskListApi(params) {
//     return AxiosAuthServices.get(TaskListURL, params);
// }

export function TaskListApi(params) {
    return AxiosAuthServices.get(ProjectTaskNewListURL, params);
}

export function TaskLogListApi(params) {
    return AxiosAuthServices.get(TaskLogURL, params);
}

export function TaskSelectionListApi(params) {
    return AxiosAuthServices.get(TaskSelectionURL, params);
}

export function TaskCommentListApi(params) {
    return AxiosAuthServices.get(TaskCommentListURL, params);
}

export function TaskCommentCreateUpdateApi(data) {
    const formData = new FormData();
    formData.append('user_id', data.user_id);
    formData.append('project_task_id', data.project_task_id);
    formData.append('remark', data.remark);

    if (data.id !== '' && data.formType === 'edit') {
        formData.append('id', data.id);
        return AxiosAuthServices.post(TaskCommentUpdateURL, formData);
    } else {
        return AxiosAuthServices.post(projectTaskCommentCreateURL, formData);
    }
}

export function TaskCommentDeleteApi(data) {
    const formData = new FormData();
    formData.append('id', data.id);
    return AxiosAuthServices.post(TaskCommentDeleteURL, formData);
}
