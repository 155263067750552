import moment from 'moment';
import {
    employeeProjectURL,
    mergeProjectInitURL,
    projectCreateURL,
    projectDeleteURL,
    projectInitURL,
    projectListURL,
    projectOverviewURL,
    projectUpdateURL
} from './ApiConstant';
import { AxiosAuthServices } from './axios/axiosServices';

export function ProjectInitApi(params) {
    return AxiosAuthServices.get(projectInitURL, params);
}

export function EmployeeProjectApi() {
    return AxiosAuthServices.get(employeeProjectURL);
}

export function ProjectOverviewApi(params) {
    return AxiosAuthServices.get(projectOverviewURL, params);
}

export function ProjectListApi(params) {
    return AxiosAuthServices.get(projectListURL, params);
}

export function ProjectCreateUpdateApi(data) {
    const formData = new FormData();
    formData.append('name', data.name);
    formData.append('client_id', data.client_id);
    formData.append('start_date', moment(data.start_date).format('YYYY/MM/DD'));
    formData.append('end_date', data.end_date == null ? '' : moment(data.end_date).format('YYYY/MM/DD'));
    formData.append('priority', data.priority);
    formData.append('description', data.description);
    formData.append('status', data.status);

    if (data.id !== '' && data.formType === 'edit') {
        formData.append('id', data.id);
        return AxiosAuthServices.post(projectUpdateURL, formData);
    } else {
        data.project_heads.map((item) => {
            formData.append('project_heads[]', item.id);
        });
        return AxiosAuthServices.post(projectCreateURL, formData);
    }
}

export function ProjectDeleteApi(data) {
    const formData = new FormData();
    formData.append('id', data.id);
    return AxiosAuthServices.post(projectDeleteURL, formData);
}

export function MergeProjectApi(params) {
    const formData = new FormData();
    formData.append('merge_into', params.merge_into);
    params.projects.map((item) => {
        formData.append(`projects[]`, item.id);
    });
    return AxiosAuthServices.post(mergeProjectInitURL, formData);
}
