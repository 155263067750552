import { Outlet } from 'react-router-dom';

// project imports
import Customization from '../Customization';
import { useEffect } from 'react';

import CompanyFavicon from 'assets/images/companyFavicon.png';

// ==============================|| MINIMAL LAYOUT ||============================== //

const MinimalLayout = () => {
    useEffect(() => {
        // Dynamically set the favicon
        const setFavicon = () => {
            const link = document.querySelector("link[rel*='icon']") || document.createElement('link');
            link.type = 'image/x-icon';
            link.rel = 'shortcut icon';
            link.href = CompanyFavicon; // Replace with the actual path or URL
            document.head.appendChild(link);
        };

        setFavicon();
    }, []);
    return (
        <>
            <Outlet />
            {/* <Customization /> */}
        </>
    );
};

export default MinimalLayout;
