import {
    LoginURL,
    downloadSingleTrackerURL,
    downloadTrackerURL,
    forgotPasswordURL,
    logoutURL,
    refreshURL,
    resetPasswordURL
} from './ApiConstant';
import { AxiosAuthServices, AxiosServices } from './axios/axiosServices';

export function LoginApi(data) {
    const formData = new FormData();
    formData.append('email', data.email);
    formData.append('password', data.password);
    return AxiosServices.post(LoginURL, formData);
}

export function SwitchUserApi(data) {
    const formData = new FormData();
    formData.append('id', data);
    return AxiosAuthServices.post('/switch-user', formData);
}

export function LogoutApi() {
    return AxiosAuthServices.post(logoutURL);
}

export function ForgotPasswordApi(data) {
    const formData = new FormData();
    formData.append('email', data.email);
    return AxiosServices.post(forgotPasswordURL, formData);
}

export function ResetPasswordApi(data) {
    const formData = new FormData();
    formData.append('token', data.token);
    formData.append('password', data.password);
    return AxiosServices.post(resetPasswordURL, formData);
}

export function downloadTrackerApi() {
    return AxiosServices.get(downloadTrackerURL);
}

export function downloadSingleTrackerApi(data) {
    return AxiosServices.post(downloadSingleTrackerURL, data);
}

export function RefreshApi() {
    return AxiosAuthServices.post(refreshURL);
}
