import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Avatar, Box, ButtonBase, useMediaQuery } from '@mui/material';

// project imports
import LogoSection from '../LogoSection';
import ProfileSection from './ProfileSection';
import NotificationSection from './NotificationSection';

// assets
import MenuIcon from '@mui/icons-material/Menu';
import { useDispatch, useSelector } from 'react-redux';
import { TASK_SELECTION } from 'store/actions';
import CenterDialog from 'ui-component/models/CenterDialog';
import TaskSelection from './TaskSelection';
import DownloadTracker from './DownloadTracker';
import { IconBrandApple, IconBrandUbuntu, IconBrandWindows } from '@tabler/icons';

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = ({ handleLeftDrawerToggle }) => {
    const theme = useTheme();
    const user = useSelector((state) => state.account).user;
    const dispatch = useDispatch();
    const taskSelect = useSelector((state) => state.common.taskSelect);

    const taskCloseHandler = () => {
        dispatch({
            type: TASK_SELECTION,
            payload: {
                taskSelect: false
            }
        });
    };

    return (
        <>
            {/* logo & toggler button */}
            <Box
                sx={{
                    width: 228,
                    display: 'flex',
                    [theme.breakpoints.down('md')]: {
                        width: 'auto'
                    }
                }}
            >
                <Box component="span" sx={{ display: { xs: 'none', md: 'block' }, flexGrow: 1 }}>
                    <LogoSection />
                </Box>
                <ButtonBase sx={{ borderRadius: '12px', overflow: 'hidden' }}>
                    <Avatar
                        aria-label="Menu"
                        variant="rounded"
                        sx={{
                            ...theme.typography.commonAvatar,
                            ...theme.typography.mediumAvatar,
                            transition: 'all .2s ease-in-out',
                            background: theme.palette.secondary[200],
                            color: theme.palette.secondary[800],
                            '&:hover': {
                                borderColor: theme.palette.secondary[800],
                                background: theme.palette.secondary[800],
                                color: theme.palette.secondary.light
                            }
                        }}
                        onClick={handleLeftDrawerToggle}
                        color="inherit"
                    >
                        <MenuIcon stroke={1.5} size="1.3rem" />
                    </Avatar>
                </ButtonBase>
            </Box>

            {/* header search */}
            {/* <SearchSection /> */}
            <Box sx={{ flexGrow: 1 }} />
            {useMediaQuery(theme.breakpoints.up('md')) && <DownloadTracker />}
            {/* notification & profile */}
            <NotificationSection />
            {/* <CurrentTask /> */}
            <ProfileSection />
            {user?.employee_tracker_settings?.project_task_track && taskSelect && (
                <CenterDialog
                    open={taskSelect}
                    title="Task Selection"
                    onClose={() => taskCloseHandler}
                    id="taskSelect"
                    // closeButton
                >
                    <TaskSelection formId="taskSelect" onSubmit={taskCloseHandler} />
                </CenterDialog>
            )}
        </>
    );
};

Header.propTypes = {
    handleLeftDrawerToggle: PropTypes.func
};

export default Header;
